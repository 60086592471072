var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      this.routeSign == "add"
                        ? _vm.$t("新增出入库单")
                        : _vm.$t("编辑出入库单")
                    ) +
                    "\n            "
                )
              ]
            )
          ]),
          [
            _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "refForm",
                    attrs: {
                      model: _vm.formObj,
                      rules: _vm.formObjRules,
                      "label-position": "top",
                      "show-message": _vm.notip
                    }
                  },
                  [
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "left" } },
                      [
                        _c("span", { staticStyle: { "font-size": "20px" } }, [
                          _vm._v(_vm._s(_vm.$t("基本信息")))
                        ])
                      ]
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("单据编号"),
                                  prop: "DocumentNo",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: {
                                    maxlength: "64",
                                    disabled: _vm.isEditLoad
                                  },
                                  model: {
                                    value: _vm.formObj.DocumentNo,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "DocumentNo", $$v)
                                    },
                                    expression: "formObj.DocumentNo"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("出入库类型"),
                                  prop: "InoutStockType",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "Sd_ContractId",
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.formObj.InoutStockType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formObj,
                                          "InoutStockType",
                                          $$v
                                        )
                                      },
                                      expression: "formObj.InoutStockType"
                                    }
                                  },
                                  _vm._l(_vm.inoutStockType, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("单据类型"),
                                  prop: "DocumentType",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "Sd_ContractId",
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.formObj.DocumentType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formObj,
                                          "DocumentType",
                                          $$v
                                        )
                                      },
                                      expression: "formObj.DocumentType"
                                    }
                                  },
                                  _vm._l(_vm.DJType, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("仓库"),
                                  prop: "Warehouse",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "Sd_DepartmentId",
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.formObj.Warehouse,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formObj, "Warehouse", $$v)
                                      },
                                      expression: "formObj.Warehouse"
                                    }
                                  },
                                  _vm._l(_vm.testData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("单据日期"),
                                  prop: "DocumentDate",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: {
                                    clearable: "",
                                    type: "date",
                                    "value-format": "yyyy-MM-dd",
                                    placeholder: " "
                                  },
                                  model: {
                                    value: _vm.formObj.DocumentDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "DocumentDate", $$v)
                                    },
                                    expression: "formObj.DocumentDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "smallTit" }, [
                      _vm._v(_vm._s(_vm.$t("物料信息")))
                    ]),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.formObj.itemTable,
                          "row-class-name": _vm.tableRowClassName,
                          height: "420",
                          "header-cell-class-name": _vm.starAdd
                        },
                        on: { "current-change": _vm.handleCurrentChange }
                      },
                      [
                        false
                          ? _c("el-table-column", [
                              _c("span", [_vm._v(_vm._s(_vm.scope.row.Id))])
                            ])
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("Index"),
                            type: "index",
                            width: "50px",
                            align: "center"
                          }
                        }),
                        !_vm.isLocked
                          ? _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("OPERATION"),
                                width: "110",
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("el-button", {
                                          attrs: {
                                            title: _vm.$t("Delete"),
                                            type: "danger",
                                            size: "small",
                                            icon: "el-icon-delete",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteItem(
                                                scope.$index,
                                                scope.row
                                              )
                                            }
                                          }
                                        }),
                                        _c("el-button", {
                                          attrs: {
                                            title: _vm.$t("Add"),
                                            type: "primary",
                                            size: "small",
                                            icon: "el-icon-plus",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addItem(
                                                scope.$index,
                                                scope.row
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                509828128
                              )
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("物料编码"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.MaterialCode))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".MaterialCode"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.Cg_Uom(scope.row)
                                                }
                                              },
                                              model: {
                                                value: scope.row.MaterialCode,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "MaterialCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.MaterialCode"
                                              }
                                            },
                                            _vm._l(_vm.codeData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("物料名称"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.MaterialName))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".MaterialName"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              model: {
                                                value: scope.row.MaterialName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "MaterialName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.MaterialName"
                                              }
                                            },
                                            _vm._l(_vm.testData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("批号"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.BatchNumber))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".BatchNumber"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              model: {
                                                value: scope.row.BatchNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "BatchNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.BatchNumber"
                                              }
                                            },
                                            _vm._l(_vm.PHData, function(item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("包号"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.BatchNumber))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".BatchNumber"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              model: {
                                                value: scope.row.BatchNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "BatchNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.BatchNumber"
                                              }
                                            },
                                            _vm._l(_vm.testData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("规格型号"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Specification))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".Specification"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.Cg_Uom(scope.row)
                                                }
                                              },
                                              model: {
                                                value: scope.row.Specification,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "Specification",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.Specification"
                                              }
                                            },
                                            _vm._l(_vm.GGXHData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("计量单位"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.MeasureUnit))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".MeasureUnit"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.Cg_Uom(scope.row)
                                                }
                                              },
                                              model: {
                                                value: scope.row.MeasureUnit,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "MeasureUnit",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.MeasureUnit"
                                              }
                                            },
                                            _vm._l(_vm.JLDWData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("物料大类"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.MaterialCategory)
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".MaterialCategory"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.Cg_Uom(scope.row)
                                                }
                                              },
                                              model: {
                                                value:
                                                  scope.row.MaterialCategory,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "MaterialCategory",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.MaterialCategory"
                                              }
                                            },
                                            _vm._l(_vm.wldlData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("物料分类"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.MaterialClassify)
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".MaterialClassify"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.Cg_Uom(scope.row)
                                                }
                                              },
                                              model: {
                                                value:
                                                  scope.row.MaterialClassify,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "MaterialClassify",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.MaterialClassify"
                                              }
                                            },
                                            _vm._l(_vm.wlflData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("出入库数量"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Quantity))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".Quantity"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input-number", {
                                            attrs: {
                                              "controls-position": "right",
                                              clearable: ""
                                            },
                                            model: {
                                              value: scope.row.Quantity,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Quantity",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Quantity"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("出入库规则"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.InoutStockRules)
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".InoutStockRules"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              model: {
                                                value:
                                                  scope.row.InoutStockRules,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "InoutStockRules",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.InoutStockRules"
                                              }
                                            },
                                            _vm._l(_vm.GZData, function(item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("仓位"),
                            width: "100",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.StoringLocation)
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".StoringLocation"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.Cg_Uom(scope.row)
                                                }
                                              },
                                              model: {
                                                value:
                                                  scope.row.StoringLocation,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "StoringLocation",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.StoringLocation"
                                              }
                                            },
                                            _vm._l(_vm.CKData, function(item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("供应商"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.SupplierCode))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".SupplierCode"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.Cg_Uom(scope.row)
                                                }
                                              },
                                              model: {
                                                value: scope.row.SupplierCode,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "SupplierCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.SupplierCode"
                                              }
                                            },
                                            _vm._l(_vm.testData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          padding: "50px 0"
                        }
                      },
                      [
                        !_vm.isLocked
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.isSaveDisable
                                },
                                on: { click: _vm.submitForm }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$t("Save")) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("el-button", { on: { click: _vm.resetForm } }, [
                          _vm._v(_vm._s(_vm.$t("Cancel")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }