<!-- 视图 -->
<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">
                    {{this.routeSign == 'add' ? $t('新增出入库单')
                           : $t('编辑出入库单')
                       }}
                </div>
            </div>
            <template>
                <div>
                    <el-form :model="formObj" ref="refForm" :rules="formObjRules" label-position="top"
                             v-bind:show-message="notip">
                        <el-divider content-position="left"><span style="font-size:20px">{{ $t('基本信息') }}</span></el-divider>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('单据编号')" prop="DocumentNo" label-width="120px">
                                    <el-input v-model="formObj.DocumentNo" style="width: calc(100% - 115px);" maxlength="64" :disabled="isEditLoad">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('出入库类型')" prop="InoutStockType" label-width="120px">
                                    <el-select v-model="formObj.InoutStockType" filterable ref='Sd_ContractId'
                                               style="width: calc(100% - 115px);" clearable placeholder="">
                                        <el-option v-for="item in inoutStockType" :key="item.value" :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('单据类型')" prop="DocumentType" label-width="120px">
                                    <el-select v-model="formObj.DocumentType" filterable ref='Sd_ContractId'
                                               style="width: calc(100% - 115px);" clearable placeholder="">
                                        <el-option v-for="item in DJType" :key="item.value" :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('仓库')" prop="Warehouse" label-width="120px">
                                    <el-select v-model="formObj.Warehouse" filterable ref='Sd_DepartmentId'
                                               style="width: calc(100% - 115px);" clearable placeholder="">
                                        <el-option v-for="item in testData" :key="item.value" :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('单据日期')" prop="DocumentDate" label-width="120px">
                                    <el-date-picker v-model="formObj.DocumentDate" style="width: calc(100% - 115px);"
                                                    clearable type="date" value-format="yyyy-MM-dd" placeholder=" ">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>

                        </el-row>

                        <div class="smallTit">{{ $t('物料信息') }}</div>
                        <el-table :data="formObj.itemTable" :row-class-name="tableRowClassName" height="420"
                                  style="width: 100%" @current-change="handleCurrentChange" :header-cell-class-name="starAdd">
                            <el-table-column v-if="false">
                                <span>{{ scope.row.Id }}</span>
                            </el-table-column>
                            <el-table-column :label="$t('Index')" type="index" width="50px" align='center'>
                            </el-table-column>
                            <el-table-column :label="$t('OPERATION')" width="110" align='center' v-if="!isLocked">
                                <template slot-scope="scope">
                                    <el-button @click="deleteItem(scope.$index, scope.row)" :title="$t('Delete')"
                                               type="danger" size="small" icon="el-icon-delete" circle></el-button>
                                    <el-button @click="addItem(scope.$index, scope.row)" :title="$t('Add')"
                                               type="primary" size="small" icon="el-icon-plus" circle></el-button>
                                </template>
                            </el-table-column>
                            <!--<el-table-column :label="$t('明细单据编号')" width="150" align='center'>
        <template slot-scope="scope">
            <span v-if="!scope.row.isEgdit">{{ scope.row.DocumentDetailNo }}</span>
            <el-form-item :prop="'itemTable.' + scope.$index + '.DocumentDetailNo'">
                <el-input v-if="scope.row.isEgdit" v-model="scope.row.DocumentDetailNo" clearable :disabled="isEditLoad">
                </el-input>
            </el-form-item>
        </template>
    </el-table-column>-->
                            <el-table-column :label="$t('物料编码')" width="120" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.MaterialCode }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.MaterialCode'">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.MaterialCode" filterable
                                                   clearable placeholder="" @change="Cg_Uom(scope.row)">
                                            <el-option v-for="item in codeData" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('物料名称')" width="120" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.MaterialName }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.MaterialName'">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.MaterialName" filterable
                                                   clearable placeholder="">
                                            <el-option v-for="item in testData" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('批号')" width="120" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.BatchNumber }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.BatchNumber'">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.BatchNumber" filterable
                                                   clearable placeholder="">
                                            <el-option v-for="item in PHData" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('包号')" width="120" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.BatchNumber }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.BatchNumber'">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.BatchNumber" filterable
                                                   clearable placeholder="">
                                            <el-option v-for="item in testData" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('规格型号')" width="120" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.Specification }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.Specification'">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.Specification" filterable
                                                   clearable placeholder="" @change="Cg_Uom(scope.row)">
                                            <el-option v-for="item in GGXHData" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('计量单位')" width="120" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.MeasureUnit }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.MeasureUnit'">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.MeasureUnit" filterable
                                                   clearable placeholder="" @change="Cg_Uom(scope.row)">
                                            <el-option v-for="item in JLDWData" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('物料大类')" width="120" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.MaterialCategory }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.MaterialCategory'">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.MaterialCategory" filterable clearable
                                                   placeholder="" @change="Cg_Uom(scope.row)">
                                            <el-option v-for="item in wldlData" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('物料分类')" width="120" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.MaterialClassify }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.MaterialClassify'">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.MaterialClassify" filterable clearable
                                                   placeholder="" @change="Cg_Uom(scope.row)">
                                            <el-option v-for="item in wlflData" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('出入库数量')" width="120" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.Quantity }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.Quantity'">
                                        <el-input-number v-if="scope.row.isEgdit" v-model="scope.row.Quantity"
                                                         controls-position="right" clearable>
                                        </el-input-number>
                                    </el-form-item>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('出入库规则')" width="120" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.InoutStockRules }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.InoutStockRules'">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.InoutStockRules" filterable clearable
                                                   placeholder="">
                                            <el-option v-for="item in GZData" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('仓位')" width="100" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.StoringLocation }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.StoringLocation'">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.StoringLocation" filterable clearable
                                                   placeholder="" @change="Cg_Uom(scope.row)">
                                            <el-option v-for="item in CKData" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('供应商')" width="120" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{ scope.row.SupplierCode }}</span>
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.SupplierCode'">
                                        <el-select v-if="scope.row.isEgdit" v-model="scope.row.SupplierCode" filterable clearable
                                                   placeholder="" @change="Cg_Uom(scope.row)">
                                            <el-option v-for="item in testData" :key="item.value" :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align:right;padding:50px 0;">
                            <el-button type="primary" :disabled="isSaveDisable" @click="submitForm" v-if="!isLocked">
                                {{
                                    $t('Save')
                                }}
                            </el-button>
                            <el-button @click="resetForm">{{ $t('Cancel') }}</el-button>
                        </div>
                    </el-form>
                </div>
            </template>
        </basic-container>
    </div>
</template>

<!-- 样式 -->
<style>
    .el-dialog__wrapper {
        transition-duration: 0.3s;
    }

    .notifyStyle {
        width: 30% !important;
        background: #FFE4E1;
    }

    table th.star div::before {
        content: ' * ';
        color: red;
    }

    .el-row_style {
        display: flex;
        flex-wrap: wrap;
        height: 90px;
    }

    .el-col_style {
        height: 45px;
    }

    .el-form--label-top .el-form-item__label {
        float: none;
        /*  display: inline-block;*/
        text-align: left;
        padding: 0 0 0px;
    }
</style>
<!-- 逻辑js -->
<script>
    //引入对象
    import { TextilePurchaseOrderIList, getInfoByDocumentNo, addInfo, editInfo, TextilePurchaseOrderIDel } from "@/api/stock/stockInout";
    import { addDetail, editDetail, delDetail } from "@/api/stock/stockInoutDetail";
    import { addStock, editStock, delStock } from "@/api/stock/stockInfo";
    import { TPurchaseUnifiedIsBool, GetBusinessNoIn } from "@/api/unified";
    export default {
        //data：返回实体对象
        data() {
            var VSupplier = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('Supplier_') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }
            };
            
            return {
                //路由传递信息
                routeSign: '', routeId: '', documentNo:'',
                //错误信息弹窗
                notip: true, notipInfo: "", notiplist: [], notificationPromise: Promise.resolve(),
                //是否显示(待补充逻辑)
                isSaveDisable: false, isLocked: false, isEditLoad: false,
                //表单信息
                formObj: {
                    //表头信息
                    HeadBusinessNoIn: '', DocumentNo: '', InoutStockType: '', DocumentType: null, Warehouse: '',  DocumentDate: null,
                    //表体信息
                    itemTable: [{
                        isEgdit: true, Id: "", DocumentNo: '', DocumentDetailNo: '', InoutStockType: '', DocumentType: null, MaterialCode: "", MaterialName: "", BatchNumber: "", Specification: "", MeasureUnit: "", MaterialCategory: "", MaterialClassify: "", Quantity: 0, InoutStockRules: "", StoringLocation: "", SupplierCode: "",
                    }],
                    ////表体信息
                    //stockTable: [{
                    //    isEgdit: true, Id: "", WarehousingDate: '', MaterialCode: "", MaterialName: "", BatchNumber: "", Specification: "", MeasureUnit: "", MaterialCategory: "", MaterialClassify: "", InventoryQuantity: 0, SupplierCode: "", Warehouse: "", StoringLocation:"",
                    //}],
                },
                formObjRules: {
                    Supplier: [{ required: true, validator: VSupplier, trigger: 'blur' }],
                },
                DJType: [
                    {
                        value: 0,
                        label: '入库',
                        num: 0
                    }, {
                        value: 1,
                        label: '出库',
                        num: 1
                    }
                ],
                wldlData: [{
                    label: '成品',
                    value: '成品'
                }, {
                    label: '原料',
                    value: '原料'
                }, {
                    label: '半成品',
                    value: '半成品'
                }, {
                    label: '下脚料',
                    value: '下脚料'
                    }],
                wlflData: [{
                    label: '成品竹节纱',
                    value: '成品竹节纱'
                }, {
                    label: '成品混纺纱',
                    value: '成品混纺纱'
                }, {
                    label: '进口棉花',
                    value: '进口棉花'
                }, {
                    label: '内地棉花',
                    value: '内地棉花'
                }, {
                    label: '半成品平纱',
                    value: '半成品平纱'
                }, {
                    label: '半成品混纺纱',
                    value: '半成品混纺纱'
                }, {
                    label: '下脚料',
                    value: '下脚料'
                }],
                inoutStockType: [
                    {
                        value: '原料采购(入库)',
                        label: '原料采购(入库)',
                        num: 1
                    }, {
                        value: '成品生产(入库)',
                        label: '成品生产(入库)',
                        num: 2
                    }, {
                        value: '成品退货(入库)',
                        label: '成品退货(入库)',
                        num: 3
                    }, {
                        value: '成品销售(出库)',
                        label: '成品销售(出库)',
                        num: 4
                    }, {
                        value: '生产投料(出库)',
                        label: '生产投料(出库)',
                        num: 5
                    }
                ],
                testData: [
                    {
                        value: '测试选项1',
                        label: '测试选项1',
                        num: 1
                    }, {
                        value: '测试选项2',
                        label: '测试选项2',
                        num: 2
                    }, {
                        value: '测试选项3',
                        label: '测试选项3',
                        num: 3
                    }, {
                        value: '测试选项4',
                        label: '测试选项4',
                        num: 4
                    }, {
                        value: '测试选项5',
                        label: '测试选项5',
                        num: 5
                    }],
                codeData: [
                        {
                        value: 'CODE10001',
                            label: 'CODE10001',
                            num: 1
                        }, {
                        value: 'CODE10002',
                            label: 'CODE10002',
                            num: 2
                        }, {
                        value: 'CODE10003',
                            label: 'CODE10003',
                            num: 3
                    }],
                PHData: [
                        {
                        value: 'CODE10001',
                            label: 'CODE10001',
                            num: 1
                        }, {
                        value: 'CODE10002',
                            label: 'CODE10002',
                            num: 2
                        }, {
                        value: 'CODE10003',
                            label: 'CODE10003',
                            num: 3
                        }]
                , GGXHData: [
                    {
                        value: '型号A',
                        label: '型号A',
                        num: 1
                    }, {
                        value: '型号B',
                        label: '型号B',
                        num: 2
                    }, {
                        value: '型号C',
                        label: '型号C',
                        num: 3
                    }]
                , JLDWData: [
                    {
                        value: '单位A',
                        label: '单位A',
                        num: 1
                    }, {
                        value: '单位B',
                        label: '单位B',
                        num: 2
                    }, {
                        value: '单位C',
                        label: '单位C',
                        num: 3
                    }],
                GZData: [
                        {
                        value: '先进先出',
                            label: '先进先出',
                            num: 1
                        }, {
                        value: '后进先出',
                            label: '后进先出',
                            num: 2
                        }, {
                        value: '加权平均',
                            label: '加权平均',
                            num: 3
                    }, {
                        value: '移动加权平均',
                        label: '移动加权平均',
                        num: 4
                    }],
                CKData: [
                        {
                        value: '仓位A',
                            label: '仓位A',
                            num: 1
                        }, {
                        value: '仓位B',
                            label: '仓位B',
                            num: 2
                        }, {
                        value: '仓位C',
                            label: '仓位C',
                            num: 3
                        }]
            }
        },
        //获取(dom✓/data✓/methods✓) computed:计算属性，是根据依赖关系进行缓存的计算，只有在它的相关依赖发生改变时才会进行更新
        computed: {
            setData() {
                return {
                }
            }
        },
        //beforeCreate（创建前）:获取(dom×/data×/methods×)--> created（创建后）:获取(dom×/data✓/methods✓)-->  beforeMount（挂载前）:获取(dom×/data✓/methods✓)--> mounted（挂载后）:获取(dom✓/data✓/methods✓)-->...
        //created：data、mounted、watch等已经完成初始化，但是el dom树还未挂载
        created() {
            this.init()
        },
        //watch:用于监听data里面的数据是否被修改，一旦修改就可以执行一些其他的操作
        watch: {
            //监听路由对象是否变化
            $route() {
                this.init()
            }
        },
        //methods:方法函数
        methods: {
            //初始加载数据
            init() {
                this.routeSign = this.$route.query.sign;
                this.routeId = this.$route.query.id;
                this.documentNo = this.$route.query.documentNo;
                if (this.routeSign == 'edit') {
                    this.isEditLoad = true;
                    this.getLoadForm();
                }
                else {
                    GetBusinessNoIn("System_PO_business", "defaultRule").then(res => {
                        this.formObj.HeadBusinessNoIn = res.data;
                    })
                }
            },
            //获取信息
            async getLoadForm() {
                await getInfoByDocumentNo(this.documentNo).then(res => {
                    var infodata = res.data;
                    this.formObj = infodata;
                    this.formObj.itemTable = infodata.itemDetails;
                    if (this.formObj.itemTable.length == 0) { this.addrow(); }
                }).catch((erro) => { console.log(erro) });
            },
            //新增行
            addItem(index, row) {
                let item =
                {
                    isEgdit: false, Id: "", DocumentDetailNo: "", DocumentType: null, MaterialCode: "", MaterialName: "", BatchNumber: "", Specification: "", MeasureUnit: "",
                    MaterialCategory: "", MaterialClassify: "", Quantity: 0, InoutStockRules: "", Warehouse: "", SupplierCode: "",
                };
                this.formObj.itemTable.splice(index, 0, item)
            },
            addrow() {
                let item = {
                    isEgdit: false, Id: "", DocumentDetailNo: "", DocumentType: null, MaterialCode: "", MaterialName: "", BatchNumber: "", Specification: "", MeasureUnit: "",
                    MaterialCategory: "", MaterialClassify: "", Quantity: 0, InoutStockRules: "", Warehouse: "", SupplierCode: "",
                }
                this.formObj.itemTable.push(item);
            },
            //删除子项
            deleteItem(index, row) {
                if (row.Id == '') {
                    this.formObj.itemTable.splice(index, 1);
                }
                else {
                    var tipInfo = this.$t('IsDel') + ":" + row.MaterialCode;
                    var Islast = false;
                    //判断是否删除
                    if (this.formObj.itemTable.length == 2 && (this.formObj.itemTable[0].Id == '' || this.formObj.itemTable[1].Id == '')) {
                        tipInfo = this.$t('When the last detail is deleted, the system will automatically delete the whole order');
                        Islast = true;
                    }
                    this.$confirm(tipInfo, this.$t('Tips'), {
                        confirmButtonText: this.$t('Yes'),
                        cancelButtonText: this.$t('Cancel'),
                        type: "warning"
                    }).then(() => {
                        delDetail(row.Id).then((res) => {
 
                            //待删除
                            var data = res.data;
                            this.$message({ showClose: false, message: data.message, type: "success" });
                           /* this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });*/
                            this.formObj.itemTable.splice(index, 1);
                            this.Cg_Amount();
                            if (Islast) {
                                this.$router.push({ path: '/stock/stockInout' });
                            }
                        });
                    })
                }
            },
            //提交数据
            submitForm() {
                this.$refs["refForm"].clearValidate(); // 移除校验结果
                for (var j = this.formObj.itemTable.length - 1; j > -1; j--) {
                    if (this.formObj.itemTable[j].MaterialCode.trim() == '' && this.formObj.itemTable[j].MaterialName.trim() == ''
                        && (this.formObj.itemTable[j].Quantity == 0)) {
                        this.formObj.itemTable.splice(j, 1);
                    }
                }
                if (this.formObj.itemTable.length == 0) {
                    this.addrow();
                    this.$message({
                        message: this.$t('No Data Submitted'),
                        type: "error"
                    });//提示无数据
                    return false;
                } else {
                    this.$nextTick(() => {//避免点击两次
                        this.notip = false;
                        this.notiplist = [];
                        let formName = "refForm";
                        this.$refs[formName].validate((valid) => {
                            if (valid) {
                                this.isSaveDisable = true;
                                this.saveINFO(this.formObj);
                            } else {
                                this.notificationPromise = this.notificationPromise.then(this.$nextTick).then(() => {
                                    let newDatas = [];
                                    const h = this.$createElement;
                                    for (let i in this.notiplist) {
                                        newDatas.push(h('p', null, this.notiplist[i]));
                                    }
                                    this.$notify.error({
                                        title: this.$t('OperationFailed'),
                                        offset: 100,
                                        showClose: true,
                                        duration: 0,
                                        message: h('div', null, newDatas),
                                        customClass: 'notifyStyle',
                                    });
                                });
                                return false;
                            }
                        });
                    });
                }
            },
            //保存订单信息
            saveINFO(headrow) {
                if (this.routeId != '') {//编辑
                    editInfo(headrow).then(() => {
                        this.formObj.itemTable.forEach(v => {
                            v.DocumentNo = headrow.DocumentNo;
                            v.DocumentType = headrow.DocumentType;
                            v.DocumentDate = headrow.DocumentDate;
                            v.InoutStockType = headrow.InoutStockType;
                            v.Warehouse = headrow.Warehouse;
                            let stock = {
                                WarehousingDate: v.DocumentDate,
                                BatchNumber: v.BatchNumber,
                                MaterialCategory: v.MaterialCategory,
                                MaterialClassify: v.MaterialClassify,
                                MaterialCode: v.MaterialCode,
                                MaterialName: v.MaterialName,
                                Specification: v.Specification,
                                MeasureUnit: v.MeasureUnit,
                                InventoryQuantity: v.Quantity,
                                Warehouse: v.Warehouse,
                                StoringLocation: v.StoringLocation,
                                SupplierCode: v.SupplierCode,
                                DocumentType: v.DocumentType,
                                IsDel:0
                            }
                            if (v.Id != '') {
                                editDetail(v).then().catch((erro) => { console.log(erro) });
                               
                            }
                            if (v.Id == '') {
                                addDetail(v).then().catch((erro) => { console.log(erro) });
                            }
                            editStock(stock).then().catch((erro) => { console.log(erro) });
                        })
                        this.$message({
                            message: this.$t('OperationSuccess'),
                            type: "success"
                        });
                        //返回列表
                        this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value == this.$route.fullPath), 1);
                        this.$router.push({ path: '/stock/stockInout' });
                    }).catch((erro) => { console.log(erro) });
                }
                if (this.routeId == '') {//新增
                    addInfo(headrow).then(res => { 
                        this.formObj.itemTable.forEach(v => {
                            v.DocumentNo = headrow.DocumentNo;
                            v.DocumentType = headrow.DocumentType;
                            v.DocumentDate = headrow.DocumentDate;
                            v.InoutStockType = headrow.InoutStockType;
                            v.Warehouse = headrow.Warehouse;
                            let stock = {
                                WarehousingDate: v.DocumentDate,
                                BatchNumber: v.BatchNumber,
                                MaterialCategory:v.MaterialCategory,
                                MaterialClassify: v.MaterialClassify,
                                MaterialCode: v.MaterialCode,
                                MaterialName: v.MaterialName,
                                Specification: v.Specification,
                                MeasureUnit: v.MeasureUnit,
                                InventoryQuantity: v.Quantity,
                                Warehouse: v.Warehouse,
                                StoringLocation: v.StoringLocation,
                                SupplierCode: v.SupplierCode,
                                DocumentType: v.DocumentType,
                                   IsDel: 0
                            };
                            if (v.Id != '') {
                                editDetail(v).then().catch((erro) => { console.log(erro) });
                            }
                            if (v.Id == '') {
                                addDetail(v).then().catch((erro) => { console.log(erro) });
                            }
                            editStock(stock).then().catch((erro) => { console.log(erro) });
                        })
                        this.$message({
                            message: this.$t('OperationSuccess'),
                            type: "success"
                        });
                        //返回列表
                        setTimeout(() => {
                            this.isSaveDisable = false;
                            this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value == this.$route.fullPath), 1);
                            this.$router.push({ path: '/stock/stockInout' });
                        }, 1000 * Math.random());
                    }).catch((erro) => { console.log(erro) });
                }
            },
            //取消按钮
            resetForm() {
                this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value == this.$route.fullPath), 1);
                this.$router.push({ path: '/textile_Purchase/POrd' });
            },
          
            //值改变-计量单位
            Cg_Uom(row) {
                if (row.MeasureUnit != "") {
                    var objrow1 = {};
                    objrow1 = this.JLDWData.find((i) => {//这里的List就是上面遍历的数据源
                        return i.value === row.value;//筛选出匹配数据
                    });
                    row.MeasureUnit = objrow1.label;
                } else {
                    row.MeasureUnit = "";
                }
            },
          
            //点击行变化
            handleCurrentChange(currentRow, oldCurrentRow) {
                if (currentRow.index == this.formObj.itemTable.length - 1) {
                    this.addrow();
                }
                if (currentRow.Id == '') {
                    this.$set(currentRow, 'isEgdit', true);
                    if (oldCurrentRow != null && oldCurrentRow != undefined) {
                        this.$set(oldCurrentRow, 'isEgdit', false);
                    }
                    return;
                } else {
                    this.$set(currentRow, 'isEgdit', true);
                    if (oldCurrentRow != null && oldCurrentRow != undefined) {
                        this.$set(oldCurrentRow, 'isEgdit', false);
                    }
                }
            },
            //添加行号
            tableRowClassName({ row, rowIndex }) {
                // 把每一行的索引放进row
                row.index = rowIndex
            },
            //列头加星号
            starAdd(obj) {
                if (obj.columnIndex === 2 || obj.columnIndex === 3 || obj.columnIndex === 10 || obj.columnIndex === 78 || obj.columnIndex === 99 || obj.columnIndex === 111) {
                    return 'star';
                }
            },
        }
    }
</script>