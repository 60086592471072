import request from '@/router/axios';
import { Console } from "console";
var textileBaseUrl = "http://localhost:5202/";
// 引入使用    import { TextilePurchaseOrderIList, TextilePurchaseOrderIById,TextilePurchaseOrderIAdd, TextilePurchaseOrderIEdit, TextilePurchaseOrderIDel} from "@/api/Purchase/TextilePurchaseOrderApi";
//显示列表查询
export const listInfo = (BatchNumber, MaterialName, MaterialCategory, Warehouse, page, pageSize) => {
    return request({
        url: textileBaseUrl + "st/Stock/GetStockInfos",
        method: "get",
        params: {
            "batchNumber": BatchNumber,
            "materialName": MaterialName,
            "materialCategory": MaterialCategory,
            "warehouse": Warehouse,
            "page": page,
            "pageSzie": pageSize
        }
    })
}

//根据documentNo获取单条信息
export const getInfoByDocumentNo = (documentNo) => {
    return request({
        url: textileBaseUrl + "st/Stock/GetStockInoutByDocumentNo",
        method: "get",
        params: {
            documentNo
        }
    })
}
//添加
export const addStock = (row) => {
    return request({
        url: textileBaseUrl + "st/Stock/PostStockInfo",
        method: "post",
        data: {
            ...row
        }
    })
}


//更新
export const editStock = (row) => {
    console.log("row", row);
    return request({
        url: textileBaseUrl + "st/Stock/PutStockInfo",
        method: "put",
        data: {
            ...row
        }
    })
}

//删除
export const delStock = (id) => {
    return request({
        url: textileBaseUrl + "st/Stock/DeleteStockInfo",
        method: "delete",
        params: {
            id
        }
    })
}


